<template>
  <section>
    <v-container class="py-0" v-if="asuulga">
      <v-row class="mt-10 mx-2">
        <v-col lg="6" md="6" cols="12">
          <div class="text-start">
            <h3>{{ asuulga.title }}</h3>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="asuulga">
      <v-card
        class="pa-4"
        v-for="(category, cindex) in categories"
        :key="'category' + cindex"
      >
        <h3>{{ category.name }}</h3>
        <v-row class="my-2" no-gutters>
          <div
            v-for="(oanswer, aindex) in category.originalAnswers"
            :key="'oanswerr' + aindex"
          >
            <v-chip
              :color="oanswer.bgcolor"
              :style="'color:' + oanswer.color"
              >{{ oanswer.name[0] }}</v-chip
            >
            <span class="mt-1 mr-5 ml-1">{{ oanswer.name }}</span>
          </div>
        </v-row>

        <table>
          <thead>
            <tr>
              <th>No</th>
              <th>Ангиуд</th>
              <th
                v-for="(question, qindex) in category.questions"
                :key="'question' + qindex"
                class="pa-0"
              >
                <span>Асуулт {{ question.questionIndex + 1 }}</span>
                <div
                  class="d-flex flex-row"
                  style="width: 100%; border-top: 1px solid #dddddd"
                >
                  <v-col
                    :style="
                      category.originalAnswers.length != aindex + 1
                        ? 'border-right: 1px solid #dddddd'
                        : ''
                    "
                    class="pa-0 px-1"
                    v-for="(oanswer, aindex) in category.originalAnswers"
                    :key="'oanswer' + aindex"
                  >
                    <span>{{ oanswer.name[0] }}</span>
                  </v-col>
                </div>
              </th>
            </tr>
          </thead>
          <tbody v-if="classGroups">
            <tr
              v-for="(classGroup, classGroupIndex) in classGroups"
              :key="classGroupIndex + classGroup.id"
            >
              <td>{{ classGroupIndex + 1 }}</td>
              <td>{{ classGroup.id }}</td>
              <td
                class="pa-0"
                v-for="(question, qindex) in getQuestions(category)"
                :key="'questionss' + qindex"
              >
                <div class="d-flex flex-row" style="width: 100%">
                  <v-col
                    :style="
                      category.originalAnswers.length != aindex + 1
                        ? 'border-right: 1px solid #dddddd'
                        : ''
                    "
                    class="pa-0 px-1"
                    v-for="(oanswer, aindex) in category.originalAnswers"
                    :key="'oanswer' + aindex"
                  >
                    <span style="line-height: 2">{{
                      _getScoreAnswer(classGroup, question, oanswer, false)
                    }}</span>
                  </v-col>
                </div>
              </td>
            </tr>
            <tr>
              <td>{{ classGroups.length + 1 }}</td>
              <td>Бүгд</td>
              <td
                class="pa-0"
                v-for="(question, qindex) in getQuestions(category)"
                :key="'questionss' + qindex"
              >
                <div class="d-flex flex-row" style="width: 100%">
                  <v-col
                    :style="
                      category.originalAnswers.length != aindex + 1
                        ? 'border-right: 1px solid #dddddd'
                        : ''
                    "
                    class="pa-0 px-1"
                    v-for="(oanswer, aindex) in category.originalAnswers"
                    :key="'oanswer' + aindex"
                  >
                    <span style="line-height: 2">{{
                      _getScoreAnswer(null, question, oanswer, true)
                    }}</span>
                  </v-col>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </v-card>

      <!-- <v-simple-table v-if="classGroups">
        <template v-slot:default>
          <thead>
            <tr>
              <th
                colspan="5"
                v-for="(question, questionIndex) in allOriginalQuestions"
                :key="questionIndex + question.id + 'head'"
              >
                Q{{ question.questionIndex }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(classGroup, classGroupIndex) in classGroups"
              :key="classGroupIndex + classGroup.id"
            >
              <td>No.</td>
              <td>{{ classGroup.id }}</td>
              <template v-if="classGroup">
                <div
                  v-for="(question, questionIndex) in allOriginalQuestions"
                  :key="questionIndex + question.id"
                >
                  <td
                    v-for="(answer, answerIndex) in question.answers"
                    class="mr-4"
                    :key="question.id + 'answer' + answerIndex"
                  > -->
      <!-- {{_getScoreAnswer(oanswer, answerIndex,classGroup,question)}} -->
      <!-- {{ answer.numberOfAnswered }}
                  </td>
                </div>
                <td>xx</td>
              </template>
            </tr>
          </tbody>
        </template>
      </v-simple-table> -->
    </v-container>
  </section>
</template>

<script>
import { mapState } from "vuex";
const fb = require("@/firebaseConfig.js");
import { sync } from "vuex-pathify";
// import AsuulgaCharts from "./AsuulgaCharts.vue";
export default {
  data: () => ({
    allReadQuestions: null,
    asuulga: null,
    classGroups: null,
    categories: null,
    allOriginalQuestions: null,
    colors: [
      { bgcolor: "#64B5F6", color: "white" },
      { bgcolor: "#90CAF9", color: "black" },
      { bgcolor: "#BBDEFB", color: "black" },
      { bgcolor: "#F4511E", color: "white" },
      { bgcolor: "#3D5AFE", color: "white" },
      { bgcolor: "#3D5AFE", color: "white" },
      { bgcolor: "#81C784", color: "black" },
      { bgcolor: "#9575CD", color: "white" },
      { bgcolor: "#DCE775", color: "black" },
      { bgcolor: "#A1887F", color: "white" },
      { bgcolor: "#FFFF00", color: "black" },
      { bgcolor: "#8D6E63", color: "white" },
      { bgcolor: "#80CBC4", color: "black" },
      { bgcolor: "#F8BBD0", color: "black" },
      { bgcolor: "#80CBC4", color: "black" },
      { bgcolor: "#BBDEFB", color: "black" },
      { bgcolor: "#C5E1A5", color: "black" },
      { bgcolor: "#4CAF50", color: "white" },
      { bgcolor: "#FF7043", color: "white" },
      { bgcolor: "#B0BEC5", color: "black" },
      { bgcolor: "#FFEA00", color: "black" },
    ],
  }),
  components: {
    // AsuulgaCharts,
  },
  props: {
    sorilId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
  },
  created() {
    fb.db
      .doc(this.sorilId)
      .get()
      .then(async (doc) => {
        if (doc.exists) {
          this.asuulga = doc.data();
          this.asuulga.ref = doc.ref;
          this.asuulga.id = doc.id;

          this._findCategories();
          this.asuulga.ref
            .collection("results-per-groups")
            .onSnapshot((docs) => {
              this.classGroups = [];
              docs.forEach((doc) => {
                let group = doc.data();
                group.id = doc.id;
                group.ref = doc.ref;
                group.allReadQuestions = null;
                group.ref.collection("questions").onSnapshot((questions) => {
                  group.allReadQuestions = [];
                  questions.forEach((question) => {
                    let q = question.data();
                    q.id = question.id;
                    group.allReadQuestions.push(q);
                  });
                });
                this.classGroups.push(group);
              });
            });
        } else {
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  },
  methods: {
    _getColor(oanswer) {
      let x = Number((Math.random() * (this.colors.length - 1)).toFixed(0));
      oanswer.bgcolor = this.colors[x].bgcolor;
      oanswer.color = this.colors[x].color;
    },
    getQuestions(cat) {
      var foundQuestions = this.allOriginalQuestions.filter(
        (question) => question.category == cat.name
      );
      return foundQuestions ? foundQuestions : [];
    },
    _getScoreAnswer(classGroup, question, oanswer, calculatingTotal) {
      if (calculatingTotal) {
        return question.answers[oanswer.answerIndex] &&
          question.answers[oanswer.answerIndex].numberOfAnswered
          ? question.answers[oanswer.answerIndex].numberOfAnswered
          : 0;
      } else {
        if (classGroup.allReadQuestions)
          var foundQuestion = classGroup.allReadQuestions.find(
            (q) => q.id == question.id
          );
        return foundQuestion &&
          foundQuestion.answers[oanswer.id] &&
          foundQuestion.answers[oanswer.id].numberOfAnswered
          ? foundQuestion.answers[oanswer.id].numberOfAnswered
          : 0;
      }
    },
    _getQuestionsOfCategory(cat) {
      cat.ref
        .collection("questions")
        .where("deleted", "==", false)
        .orderBy("questionIndex", "asc")
        .onSnapshot((querySnapshot) => {
          cat.questions = [];
          querySnapshot.forEach((doc) => {
            let question = doc.data();
            question.category = cat.name;
            // question.statData = null;
            // question.datacollection = null;

            question.id = doc.id;
            question.ref = doc.ref;
            question.answers = [];
            question.answered = false;
            question.countAnswers = 0;
            // question.currentAnswerActive = false;
            // question.answeredCount = null;
            // question.useranswer = null; // OORCHLOGDDOG DATAG OMNO NI ZAAVAL TODORHOILJ UTGA ONOOH

            question.answers = [];
            // this._getAnswers(question, doc.ref);

            doc.ref
              .collection("answers")
              .orderBy("answerIndex", "asc")
              .onSnapshot((querySnapshot) => {
                question.answers = [];
                querySnapshot.forEach(async (doc) => {
                  const answer = doc.data();
                  answer.id = doc.id;
                  question.answers.push(answer);
                });
              });

            cat.questions.push(question);
            this.allOriginalQuestions.push(question);
            if (this.selectedQuestion == null) this.selectedQuestion = question;
            // }
          });
        });
    },
    async _findCategories() {
      this.asuulga.ref
        .collection("categories")
        .orderBy("name", "asc")
        .onSnapshot((querySnapshot) => {
          this.categories = [];
          this.allOriginalQuestions = [];
          querySnapshot.forEach((catDoc) => {
            const cat = catDoc.data();
            cat.id = catDoc.id;
            cat.ref = catDoc.ref;
            cat.questions = [];
            cat.originalAnswers = [];
            cat.ref
              .collection("category-answers")
              .orderBy("answerIndex", "asc")
              .get()
              .then((docs) => {
                docs.forEach((doc) => {
                  let oanswer = doc.data();
                  oanswer.ref = doc.ref;
                  oanswer.id = doc.id;
                  this._getColor(oanswer);
                  cat.originalAnswers.push(oanswer);
                });
              });
            this._getQuestionsOfCategory(cat);
            this.categories.push(cat);
            if (this.categories.length === 1) {
              this.category = this.categories[0];
            }
          });
        });
    },
  },
};
</script>
<style>
.theme--light.v-tabs-items {
  background-color: #eeeeee;
}
.outlined-card {
  border: 1px solid #e0e0e0;
}
.outlined-card:hover {
  border: 4px solid #e0e0e0;
}
.asuulga table {
  font-family: arial, sans-serif;
  border-collapse: collapse !important;
  width: 100%;
  text-align: center;
}

.asuulga td,
.asuulga th {
  font-size: 12px;
  border: 1px solid #dddddd;
  padding: 2px;
}

.asuulga tr:nth-child(even) {
  background-color: #dddddd;
}
</style>