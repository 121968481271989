var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[(_vm.asuulga)?_c('v-container',{staticClass:"py-0"},[_c('v-row',{staticClass:"mt-10 mx-2"},[_c('v-col',{attrs:{"lg":"6","md":"6","cols":"12"}},[_c('div',{staticClass:"text-start"},[_c('h3',[_vm._v(_vm._s(_vm.asuulga.title))])])])],1)],1):_vm._e(),_c('v-container',{staticClass:"asuulga"},_vm._l((_vm.categories),function(category,cindex){return _c('v-card',{key:'category' + cindex,staticClass:"pa-4"},[_c('h3',[_vm._v(_vm._s(category.name))]),_c('v-row',{staticClass:"my-2",attrs:{"no-gutters":""}},_vm._l((category.originalAnswers),function(oanswer,aindex){return _c('div',{key:'oanswerr' + aindex},[_c('v-chip',{style:('color:' + oanswer.color),attrs:{"color":oanswer.bgcolor}},[_vm._v(_vm._s(oanswer.name[0]))]),_c('span',{staticClass:"mt-1 mr-5 ml-1"},[_vm._v(_vm._s(oanswer.name))])],1)}),0),_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("No")]),_c('th',[_vm._v("Ангиуд")]),_vm._l((category.questions),function(question,qindex){return _c('th',{key:'question' + qindex,staticClass:"pa-0"},[_c('span',[_vm._v("Асуулт "+_vm._s(question.questionIndex + 1))]),_c('div',{staticClass:"d-flex flex-row",staticStyle:{"width":"100%","border-top":"1px solid #dddddd"}},_vm._l((category.originalAnswers),function(oanswer,aindex){return _c('v-col',{key:'oanswer' + aindex,staticClass:"pa-0 px-1",style:(category.originalAnswers.length != aindex + 1
                      ? 'border-right: 1px solid #dddddd'
                      : '')},[_c('span',[_vm._v(_vm._s(oanswer.name[0]))])])}),1)])})],2)]),(_vm.classGroups)?_c('tbody',[_vm._l((_vm.classGroups),function(classGroup,classGroupIndex){return _c('tr',{key:classGroupIndex + classGroup.id},[_c('td',[_vm._v(_vm._s(classGroupIndex + 1))]),_c('td',[_vm._v(_vm._s(classGroup.id))]),_vm._l((_vm.getQuestions(category)),function(question,qindex){return _c('td',{key:'questionss' + qindex,staticClass:"pa-0"},[_c('div',{staticClass:"d-flex flex-row",staticStyle:{"width":"100%"}},_vm._l((category.originalAnswers),function(oanswer,aindex){return _c('v-col',{key:'oanswer' + aindex,staticClass:"pa-0 px-1",style:(category.originalAnswers.length != aindex + 1
                      ? 'border-right: 1px solid #dddddd'
                      : '')},[_c('span',{staticStyle:{"line-height":"2"}},[_vm._v(_vm._s(_vm._getScoreAnswer(classGroup, question, oanswer, false)))])])}),1)])})],2)}),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.classGroups.length + 1))]),_c('td',[_vm._v("Бүгд")]),_vm._l((_vm.getQuestions(category)),function(question,qindex){return _c('td',{key:'questionss' + qindex,staticClass:"pa-0"},[_c('div',{staticClass:"d-flex flex-row",staticStyle:{"width":"100%"}},_vm._l((category.originalAnswers),function(oanswer,aindex){return _c('v-col',{key:'oanswer' + aindex,staticClass:"pa-0 px-1",style:(category.originalAnswers.length != aindex + 1
                      ? 'border-right: 1px solid #dddddd'
                      : '')},[_c('span',{staticStyle:{"line-height":"2"}},[_vm._v(_vm._s(_vm._getScoreAnswer(null, question, oanswer, true)))])])}),1)])})],2)],2):_vm._e()])],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }